<template>
  <div class="certification">
    <headbox title="营业执照认证" bgColor="#39394D" :isbackperson="true" isborder="false" :path="path"></headbox>
    <div class="cerbox">
      <div class="tip">
        为保证您的账户安全，请完成营业执照认证
      </div>
      <div class="license">
        <h1 class="screenshot">请上传营业执照</h1>
        <van-uploader v-model="fileList" :after-read="afterReadLicense" multiple :max-count="1" preview-size="200" border="false">
          <div class="file">
            <div class="one"></div>
            <div class="two"></div>
          </div>
        </van-uploader>
      </div>
      <div class="tc">
        <el-button class="btn_yellow" :style="{background:nextBox?'#39394D':'#39394D'}" :disabled="!nextBox" @click="nextPage">上传</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import * as api from "@/utils/api";
import axios from "axios";

export default {
  data() {
    return {
      nextBox: false,
      licenseKey: "",
      fileList: [],
      localtoken: localStorage.getItem("token"),
      path: "",
      fileBaseUrl: process.env.NODE_ENV === "dev" ? "/shenzhenyht" : "https://xinbaihe.oss-cn-shenzhen.aliyuncs.com"
    };
  },

  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo")) || {};
    this.path = this.$route.query.path ? this.$route.query.path : "person";
  },

  methods: {
    // 营业执照图片上传的回调
    afterReadLicense(file) {
      const data = { size: 1 };
      // debugger;
      api.apiFile(data).then((res) => {
        this.licenseKey = res[0].key;
        this.uploadFile(file.content, res[0].url);
      })
    },

    // 第二步上传文件
    uploadFile(imgpath, url) {
      // debugger;
      // 去掉url域名 保留后面
      const urllast =
        "/" +
        url
          .split("/")
          .slice(3)
          .join("/");
      const imgarr = this.base64ToUint8Array(imgpath);
      axios({
        url: this.fileBaseUrl + urllast,
        method: "put",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + this.localtoken
        },
        data: imgarr
      }).then(res => {
        if (res.status === 200) {
          this.nextBox = true;
        } else {
          this.$toast("营业执照上传失败");
        }
      });
    },

    base64ToUint8Array(base64String) {
      // debugger;
      const base64clear = base64String.substring(base64String.indexOf(",") + 1);
      const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
      const base64 = (base64clear + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    // 上传
    nextPage() {
      // debugger;
      if (!this.licenseKey) {
        this.$toast("请重新上传营业执照");
      } else {
        api.getUserLicense({ licenseKey: this.licenseKey }).then((res) => {
          if (this.path && this.userinfo.sign) {
            this.$router.push({
              name: "pricing"
            });
          } else {
            this.$router.push({
              name: "AccountContract",
              query: this.path
            });
          }
        });
      }
    }
  },
  components: {
    headbox
  }
};
</script>

<style lang="scss" scoped="true">
@import "@/assets/styles/index.sass";
::v-deep {
  .van-uploader__input-wrapper {
    height: 220px;
  }
}
.certification {
  background-color: #f5f5f5;
  height: 100%;
}
.cerbox {
  padding: 0 30px 368px;
  .tip {
    color: #b8b8b8;
    font-size: 28px;
    line-height: 78px;
  }
}
.license {
  padding: 35px 30px 20px;
  border-radius: 8px;
  background-color: #fff;
  h1 {
    text-align: left;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 32px;
    color: #242424;
  }
}

.file {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px dashed #ccc;
  .one {
    width: 90px;
    height: 1px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 30px;
  }
  .two {
    width: 1px;
    height: 90px;
    background-color: #ccc;
    position: absolute;
    top: 32px;
    left: 50%;
  }
}

.btn_yellow {
  margin-top: 70px;
  color: #f6d0ab;
  border: 0;
  width: 660px;
  height: 88px;
  font-size: 36px;
  border-radius: 50px;
}
</style>
