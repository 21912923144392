var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "certification" },
    [
      _c("headbox", {
        attrs: {
          title: "营业执照认证",
          bgColor: "#39394D",
          isbackperson: true,
          isborder: "false",
          path: _vm.path
        }
      }),
      _c("div", { staticClass: "cerbox" }, [
        _c("div", { staticClass: "tip" }, [
          _vm._v(" 为保证您的账户安全，请完成营业执照认证 ")
        ]),
        _c(
          "div",
          { staticClass: "license" },
          [
            _c("h1", { staticClass: "screenshot" }, [_vm._v("请上传营业执照")]),
            _c(
              "van-uploader",
              {
                attrs: {
                  "after-read": _vm.afterReadLicense,
                  multiple: "",
                  "max-count": 1,
                  "preview-size": "200",
                  border: "false"
                },
                model: {
                  value: _vm.fileList,
                  callback: function($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList"
                }
              },
              [
                _c("div", { staticClass: "file" }, [
                  _c("div", { staticClass: "one" }),
                  _c("div", { staticClass: "two" })
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tc" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn_yellow",
                style: { background: _vm.nextBox ? "#39394D" : "#39394D" },
                attrs: { disabled: !_vm.nextBox },
                on: { click: _vm.nextPage }
              },
              [_vm._v("上传")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }